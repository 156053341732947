export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    background: 'unset',
    '.container': {
      background: ['', '', '', 'unset'],
      backgroundColor: ['unset', '', '', 'white']
    },

    '.containerScrolled .navItem a': {
      color: ['', '', '', 'white']
    },
    '.hamburger': {
      div: {
        backgroundColor: 'primary'
      }
    },
    '.logo, .logoScrolled ': {
      display: 'none'
    },
    '.navMenu': {
      alignItems: ['center', '', '', 'flex-end'],
      backgroundColor: ['primary', '', '', 'unset']
    },
    '.menuLogoContainer': {
      marginBottom: ['0.5rem !important', '1rem', '2rem'],
      img: {
        maxWidth: '170px',
        width: '100%'
      }
    },
    '.socialIcons': {
      display: ['', '', '', 'none'],
      marginTop: ['2rem', '', '', '0rem']
    },
    '.navItem': {
      margin: ['0.5rem', '', '', '1rem 0.5rem'],
      a: {
        color: ['white', '', '', 'black'],
        fontWeight: 'bold',
        letterSpacing: '1px',
        flexDirection: 'column',
        fontSize: ['1rem', '', '0.85rem'],
        // animation for nav items
        '::after': {
          content: '""',
          display: 'block',
          width: '0px',
          height: '2px',
          backgroundColor: 'primary',
          letterSpacing: '0.1em',
          textAlign: 'center',
          transition: '0.2s ease-in-out'
        },
        ':hover': {
          '::after': {
            color: 'primary',
            position: 'relative',
            left: '0px',
            width: '100%'
          }
        }
      }
    },
    '.navImage': {
      padding: ['', '', '', '', '0.2rem'],
      transition: 'all ease-in-out 0.3s',
      maxHeight: ['60px'],
      ':hover': {
        transform: 'rotate(5deg)'
      }
    },
    '.navBlock': {
      backgroundColor: 'rgba(30, 4, 4, 0.7)'
    }
  },

  footer: {
    backgroundColor: '#292929',
    color: 'white',
    paddingBottom: ['6rem', '', '', '1rem'],
    maxWidth: 'unset',
    svg: {
      path: {
        fill: 'primary'
      }
    },
    '.multiButtonContainer': {
      a: {
        color: 'primary',
        border: 'unset',
        fontFamily: 'BW Vivan Light',
        letterSpacing: '1px',
        fontWeight: '900',
        ':hover': {
          color: 'white'
        }
      }
    },
    '.bottomBar': {
      borderRadius: '0px',
      borderWidth: '2px'
    },
    '.contactDetails-container': {
      textAlign: 'left'
    },
    '.gonationLogo': {
      svg: {
        path: {
          fill: 'none'
        }
      }
    }
  },

  ctaWidget: {
    zIndex: '999',
    color: 'light',
    a: {
      fontSize: '1rem'
    }
  },

  pageHero: {
    height: '60vh',
    h1: {
      letterSpacing: '3px',
      textTransform: 'uppercase',
      color: 'primary',
      borderBottom: '3px solid',
      borderColor: 'primary',
      paddingBottom: '0.5rem',
      background: 'rgba(0,0,0,0.35)',
      padding: '1rem'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    color: ['black', '', 'white'],
    '.hero_content_container': {
      backgroundColor: ['', '', 'rgba(125,117,117,0.59)'],
      border: 'none',
      width: ['', '', '60%'],
      a: {
        alignSelf: ['center', '', 'center'],
        span: {
          color: '#d9c69e',
          fontFamily: 'BW Vivant Light',
          marginTop: '2rem',
          marginRight: '0px',
          borderTop: '2px solid',
          paddingTop: '0.5rem',
          fontWeight: 'bold',
          backgroundColor: ['', '', 'rgba(0,0,0,0.35)'],
          fontSize: '1.25rem',
          padding: '1rem',
          textTransform: 'uppercase',
          letterSpacing: '1px',
          ':hover': {
            backgroundColor: ['', '', 'rgba(0,0,0,0.85)']
          }
        }
      }
    },
    '.title': {
      fontWeight: 'bold',
      fontSize: ['2rem', '2.5rem', ''],
      color: ['secondary', '', 'primary'],
      '::after': {
        left: '0px',
        width: '100%',
        height: '2px',
        marginTop: '0.5rem',
        backgroundColor: ['primary', '', 'white'],
        opacity: '0.7',
        content: '""',
        position: 'relative',
        width: '100%'
      }
    },
    '.subtitle': {
      fontWeight: 'bold',
      color: ['#4d4d4d', '', 'white']
    },
    '.logoHero': {
      display: ['block'],
      position: ['absolute', '', 'unset'],
      top: '25vh',
      width: ['90%', '', 'unset'],
      left: '50%',
      transform: ['translate(-50%,-50%)', '', 'unset'],
      backgroundColor: ['rgba(0,0,0,0.35)', '', 'unset']
    },

    '.slick-slide img': {
      filter: ['brightness(0.7)', '', 'brightness(0.95)']
    }
  },

  homepageHeroShout: {
    '.shoutImage': {
      borderRadius: '0px'
    },
    '.content': {
      textAlign: 'center'
    },
    '.title': {
      color: ['black', '', 'white'],
      position: 'relative',
      display: 'table',
      whiteSpace: 'nowrap',
      textAlign: 'center',
      fontSize: ['1rem', '1rem', '1rem'],
      '::before, ::after': {
        borderTop: '2px solid',
        borderColor: 'primary',
        content: '""',
        display: 'table-cell',
        position: 'relative',
        top: '0.65em',
        width: '30%',
        backgroundColor: 'unset'
      },
      '::before': { right: '1.5%' },
      '::after': { left: '1.5%', opacity: '1' }
    },
    '.text': {
      fontWeight: 'bold',
      color: ['black', '', 'white']
    }
  },

  homepageMeetTeam: {
    backgroundColor: 'backgroundSecondary',
    padding: ['2rem 1rem', '', '1rem'],
    img: {
      boxShadow: '2px 2px 8px black'
    },
    '.title': {
      letterSpacing: '4px',
      textTransform: 'uppercase'
    },
    '.text': {
      color: '#545454',
      fontWeight: '500',
      maxWidth: '700px'
    },
    '.content': {
      maxWidth: '767px'
    },
    a: {
      span: {
        backgroundColor: 'black',
        color: 'white',
        margin: '0px',
        ':hover': {
          opacity: '0.8'
        }
      }
    }
  },

  homepageAbout: {
    padding: ['1rem', , '2rem'],
    '.title': {
      color: 'grey',
      fontWeight: '400'
    },
    '.subtitle': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '2px'
    },
    '.content': {
      padding: ['1rem 0rem', , 8]
    },
    img: {
      maxHeight: '70vh'
    },
    a: {
      span: {}
    }
  },

  homepageJoin: {
    variant: 'customVariants.homepageAbout',
    '.content': {
      order: ['', '', '1'],
      left: '3vw',
      padding: ['1rem 0rem', , 8]
    },
    '.lazyload-wrapper': {
      order: ['', '', '2']
    }
  },

  homepageAim: {
    backgroundColor: 'backgroundSecondary',
    color: 'text',
    paddingY: '5rem',
    paddingX: '1rem',
    '.title': {
      fontSize: '2rem',
      textAlign: 'center',
      color: 'white',
      textTransform: 'uppercase',
      letterSpacing: '2px',
      fontSize: '1.25rem',
      fontWeight: '500'
    },
    '.text': {
      fontSize: ['2rem', '', '3rem'],
      textAlign: 'center',
      color: '#545454',
      fontFamily: 'Reenie Beanie, cursive !important'
    }
  },

  homepageSectionNavLinksTitle: {
    padding: ['4rem 1rem 0rem'],
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%',
    maxWidth: 'unset',
    h2: {
      color: '#505050',
      textTransform: 'uppercase',
      fontSize: '2rem',
      letterSpacing: '2px'
    }
  },
  sectionNavLinks: {
    '.linkName': {
      fontFamily: 'EB Garamond, serif !important',
      letterSpacing: '2px',
      fontSize: ['1.5rem', '', '2rem'],
      color: 'white'
    },
    padding: '3rem 0rem'
  },

  homepageTestimonials: {
    padding: ['2rem 1rem', '', '4rem 1rem'],
    maxWidth: 'unset',
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      margin: '0 auto 1rem'
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem'
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center'
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem']
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'primary',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem'
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    }
  },

  homepageContactUs: {
    color: 'text',
    padding: '8rem 1rem',
    backgroundColor: 'backgroundSecondary',
    textAlign: 'center',
    h2: {
      color: '#3f3f3e',
      textTransform: 'uppercase',
      fontSize: '2rem',
      letterSpacing: '2px',
      maxWidth: '600px'
    },
    a: {
      backgroundColor: '#3f3f3e',
      color: 'white'
    }
  },

  homepageSlider: {
    padding: '0rem',
    '> div': {
      padding: '0.5rem 0px'
    },
    '.slick-slide img': {
      margin: '0rem 0.75rem'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    flexDirection: ['column', 'column', 'column', 'row'],
    padding: ['1rem', , '2rem'],
    '.title': {
      color: 'grey',
      fontWeight: '400'
    },
    '.subtitle': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '2px'
    },
    '.content': {
      order: ['', '', '1'],
      left: '3vw',
      borderTop: ['', '', 'solid 10px'],
      borderColor: ['', '', 'primary'],
      padding: ['1rem 0rem', , 8],
      width: ['100%', '100%', '100%', '50%']
    },
    '.image': {
      order: ['', '', '2'],
      width: ['100%', '100%', '100%', '100%']
    },
    img: {},
    a: {
      span: {}
    }
  },

  aboutSectionNavLinks: {
    h2: {
      backgroundColor: 'primary',
      width: '100%'
    },
    '> div > div > div': {
      flexGrow: 'unset',
      boxShadow: '2px 2px 8px #333333'
    }
  },

  aboutStylistLink: {
    color: 'text',
    padding: ['8rem 1rem', '', '12rem 1rem'],
    backgroundColor: 'backgroundSecondary',
    backgroundImage:
      "linear-gradient( rgba(255, 255, 255, 0.25),rgba(255, 255, 255, 0.5) ),url('https://res.cloudinary.com/gonation/image/upload/v1602876979/sites/steven-nicole/close-up-of-professional-scissors-in-hair-salon-WMS6ZGD.jpg')",
    textAlign: 'center',
    h2: {
      color: 'black',
      textTransform: 'uppercase',
      fontSize: '2rem',
      letterSpacing: '2px',
      maxWidth: '600px',
      marginBottom: '2rem'
    },
    a: {
      span: {
        backgroundColor: '#3f3f3e',
        color: 'white'
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    '.menuItemContainerImgActive, .menuItemContainer': {
      width: '100%',
      overflow: 'unset',
      margin: ['', '', '3rem 0rem']
    },

    '.menuItemInnerContainer': {
      flexDirection: ['column', '', 'row'],
      backgroundColor: ['', '', '#e8e8e8']
    },

    '.menuItemContentContainer': {
      width: ['100%', '', '50%'],
      padding: ['', '', '2rem'],
      justifyContent: ['', '', 'center'],
      alignItems: ['', '', 'Flex-start'],
      flexBasis: 'unset',
      a: {
        backgroundColor: 'black',
        color: 'white',
        margin: '0px',
        padding: '1rem',
        textAlign: 'center',
        marginTop: '2rem',
        ':hover': {
          backgroundColor: 'primary'
        }
      }
    },

    '.menuItemImageContainer': {
      width: ['100%', '', '50%'],
      maxHeight: 'unset',
      maxWidth: 'unset',
      position: ['', '', 'relative'],
      left: ['', '', '-1rem'],
      top: ['', '', '-2rem'],
      boxShadow: '2px 3px 12px grey'
    },
    '.menuItemImg': {
      border: 'solid white 0.5rem',
      paddingRight: '0px'
    },
    '.menuItemName': {
      letterSpacing: '3px',
      textTransform: 'uppercase',
      color: 'primary',
      padding: '1rem 0',
      fontFamily: 'BW Vivant Light',
      fontSize: ['1.5rem', '', '', '2rem']
    },
    '.menuItemDescription': {
      lineHeight: '1.75',
      fontSize: '1rem'
    }
  },

  // ? ========================
  // ? ====  Services page  ====
  // ? ========================

  services: {
    '.menuSectionTitle': {
      fontFamily: 'heading',
      fontSize: '3rem',
      color: 'secondary'
    },
    '.menuItemContainerImgActive, .menuItemContainer': {},

    '.menuItemInnerContainer': {},

    '.menuItemContentContainer': {},

    '.menuItemName': {
      textTransform: 'uppercase',
      fontSize: ['1rem', '', '1.25rem', '1.5rem'],
      letterSpacing: '2px'
    },

    '.menuItemPriceVariants': {
      fontSize: '1.5rem'
    },
    '.menuItemPriceLabel': {
      order: '2',
      fontSize: ['1rem', '', '1.25rem', '1.5rem']
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    padding: '1rem',
    '.albumTitle': {
      textTransform: 'uppercase',
      fontSize: ['2rem', '2rem', '2.5rem']
    },
    '.albumsCTA': {
      flexGrow: '1',
      width: ['', '', '30%'],
      height: ['', '', '375px'],
      margin: '0.5rem 0.5rem 1rem'
    },
    '.backButton': {
      borderRadius: '0px'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Join our Team  ====
  // ? ========================

  joinOurTeamAbout: {
    variant: 'customVariants.homepageAbout'
  },

  benefitsTitle: {
    padding: ['4rem 1rem 0rem'],
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%',
    maxWidth: 'unset',
    h2: {
      color: '#505050',
      textTransform: 'uppercase',
      fontSize: '2rem',
      letterSpacing: '2px'
    }
  },

  benefitsList: {
    marginBottom: ['4rem', , , '8rem'],
    '& > div': {
      justifyContent: 'center',
      textAlign: 'center',
      padding: '1rem',
      width: '100%',
      alignItems: 'stretch',
      flexWrap: 'wrap'
    },
    '& div > a ': {
      width: '300px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'solid 1px black',
      margin: '0.5rem',
      padding: '2rem 1rem',
      ':hover': {
        opacity: '0.5'
      }
    },
    '& > div h3': {
      fontSize: ['1.25rem', '1.5rem']
    }
  },

  applyHere: {
    backgroundColor: 'backgroundSecondary',
    color: 'text',
    paddingY: '5rem',
    paddingX: '1rem',
    '.title': {
      fontSize: '2rem',
      textAlign: 'center',
      color: 'white',
      textTransform: 'uppercase',
      letterSpacing: '2px',
      fontSize: '1.25rem',
      fontWeight: '500'
    },
    '.text': {
      fontSize: ['1.5rem', '', '2rem'],
      textAlign: 'center',
      color: '#545454'
    }
  },

  // ? ========================
  // ? =====  Covid page  =====
  // ? ========================

  covidMenu: {
    maxWidth: '767px',
    marginY: '1rem',
    border: 'solid 1px',
    borderColor: 'primary',
    '*': {
      boxShadow: 'none'
    },
    h2: {
      color: 'primary'
    },
    '.innerMenuContainer': {
      maxWidth: '767px'
    },
    '.menuItemContainer': {
      width: ['100%', '100%', '100%', '100%']
    },
    '.menuItemName': {
      fontSize: '1.2rem',
      color: '#4b4b4b',
      fontWeight: '400'
    },
    '.menuItemContentContainer': {
      padding: '0rem'
    },
    '.menuSectionDescription': {
      fontWeight: 'bold',
      marginBottom: '2rem'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    color: 'black',
    h2: {
      textTransform: 'uppercase',
      color: '#EAE3D1'
    }
  },

  locationMap: {
    order: '2',
    h3: {
      textTransform: 'uppercase',
      color: '#EAE3D1'
    }
  }
}
